<script setup lang="ts">
interface Props {
        multiple?: boolean;
        initialValue?: _VTI_TYPE_Array | _VTI_TYPE_UserCollection | _VTI_TYPE_User | number | null;
        closeOnSelect?: boolean;
        submenuPosition?: 'left-0' | 'right-0';
        placeholderContainerClass?: string;
        placeholder?: string;
        disabled?: boolean;
    }
    import { storeToRefs } from 'pinia'
    
    
    const emit = defineEmits(['selected', 'hidden']);
    const { t } = useTranslation();
    
    const props = defineProps({
  multiple: { type: Boolean, default: false },
  initialValue: { default: null },
  closeOnSelect: { type: Boolean, default: true },
  submenuPosition: { default: 'left-0' },
  placeholderContainerClass: { default: 'px-3 py-2 transition-all border border-gray-200 rounded cursor-pointer hover:bg-neutral-100 active:bg-neutral-200 hover:text-primary group' },
  placeholder: null,
  disabled: { type: Boolean, default: false }
});
    const submenu = ref(null);
    const { tenant } = storeToRefs(useAuthStore());
    const selected: Ref<User | Array<User> | null> = ref(null);
    const isSelected = (user: User | null) => {
        if (!selected.value || !user) {
            return false;
        }
        if (typeof selected.value?.isCollection === 'function') {
            selected.value = selected.value.toArray();
        }
        if (!Array.isArray(selected.value)) {
            return selected.value?.getId() == user.getId();
        }
        return selected.value?.some((u) => u.getId() == user.getId());
    };
    const unselect = (user: User) => {
        if (!selected.value) {
            return;
        }
        if (!Array.isArray(selected.value)) {
            selected.value = null;
        } else {
            selected.value = selected.value.filter((u) => u.getId() !== user.getId());
        }
        return onChange();
    };
    const select = (user: User | null) => {
        if (!props.multiple) {
            selected.value = user;
            return onChange();
        }
        if (!selected.value) {
            selected.value = [];
        }
        if (isSelected(user)) {
            selected.value = selected.value.filter((u) => u.getId() !== user.getId());
        } else if (user) {
            selected.value = [...selected.value, user];
        }
        return onChange();
    };
    const onChange = () => {
        if (props.multiple && selected.value && selected.value.length == 0) {
            selected.value = null;
        }
        emit('selected', selected.value);
        if (props.closeOnSelect) {
            submenu.value?.hide();
        }
    };
    const setValue = (value: User | Array<User>) => {
        selected.value = value;
    };
    const multipleUsersText = computed(() => {
        if (!selected.value || !Array.isArray(selected.value)) {
            return '';
        }
        return selected.value.map((u) => u.displayName).join(', ');
    });
    const show = () => {
        if (!submenu.value?.isDisplayed) {
            submenu.value?.show();
        }
    };
    const isDisplayed = computed(() => submenu.value?.isDisplayed);
    const displayInactiveUsers = ref(localStorage.getItem('common_user_select_display_inactive_users') == '1');
    const displayedUsers = computed<User[]>(() => (displayInactiveUsers.value ? tenant.value?.users?.get() : tenant.value?.activeUsers));
    const toggleDisplayInactiveUsers = () => {
        displayInactiveUsers.value = !displayInactiveUsers.value;
        localStorage.setItem('common_user_select_display_inactive_users', displayInactiveUsers.value ? '1' : '0');
    };
    defineExpose({
        show,
        isDisplayed,
        setValue,
    });
    onMounted(async () => {
        if (props.initialValue && props.initialValue instanceof Array) {
            selected.value = props.initialValue;
            return;
        }
        if (props.initialValue && typeof props.initialValue !== 'string' && (typeof props.initialValue?.isModelOrCollection === 'function' || typeof props.initialValue === 'object')) {
            selected.value = props.initialValue;
            return;
        }
        if (typeof props.initialValue === 'string' && props.initialValue !== '') {
            const ids = props.initialValue.split(',');
            selected.value = tenant.value?.users?.filterByIds(ids) ?? [];
        }
    });
</script>

<template>
    <Submenu
        ref="submenu"
        :position="submenuPosition"
        :container="$theme('submenu.container.xl')"
        max-height="max-h-[400px]"
        @hidden="emit('hidden', selected)"
    >
        <template #default="slotProps">
            <div
                :class="placeholderContainerClass"
                @click.prevent.stop="!disabled ? slotProps.toggle() : null"
            >
                <slot
                    v-if="selected"
                    name="selected"
                    :selected="selected"
                >
                    <div class="relative flex items-center space-x-2">
                        <template v-if="multiple">
                            <UsersAvatarList
                                :users="selected"
                                negative-margin="-ml-4"
                                size="w-[24px] h-[24px]"
                                :clickable-avatar="false"
                            ></UsersAvatarList>
                            <span class="text-sm transition-all text-neutral-500 group-hover:text-primary">{{ multipleUsersText }}</span>
                        </template>
                        <template v-else>
                            <AuthUserAvatar
                                :user="selected"
                                size="h-6 w-6"
                                :clickable-avatar="false"
                            />
                            <div>
                                <span class="text-sm transition-all text-neutral-500 group-hover:text-primary">{{ selected.name }}</span>
                            </div>
                        </template>
                    </div>
                </slot>
                <slot
                    v-else
                    name="placeholder"
                >
                    <div class="flex items-center space-x-2">
                        <div class="w-[24px] text-center">
                            <i class="transition-all text-neutral-600 group-hover:text-primary fa-regular fa-user"></i>
                        </div>
                        <div>
                            <span class="text-sm transition-all text-neutral-500 group-hover:text-primary">
                                {{ placeholder ?? $t('selects.users.placeholder') }}
                            </span>
                        </div>
                    </div>
                </slot>
            </div>
        </template>

        <template #submenu>
            <div class="w-full min-w-[260px]">
                <div class="flex items-center justify-between mb-2">
                    <p>
                        {{ $t('selects.users.title') }}
                    </p>
                    <div
                        :data-tooltip="displayInactiveUsers ? $t('actions.hide_inactive_users') : $t('actions.display_inactive_users')"
                        data-tooltip-location="left"
                    >
                        <i
                            class="text-xs cursor-pointer fa-regular text-neutral-400 hover:text-primary"
                            :class="displayInactiveUsers ? 'fa-eye' : 'fa-eye-slash'"
                            @click="toggleDisplayInactiveUsers"
                        />
                    </div>
                </div>
                <div class="flex flex-col">
                    <div
                        v-for="user in displayedUsers"
                        :key="user.getInternalId()"
                        :class="
                            $theme('contextmenu.link', 'cursor-pointer', {
                                'px-6 py-3': 'p-2',
                                'space-x-2': 'space-x-3',
                            })
                        "
                        @click.prevent="select(user)"
                    >
                        <div
                            class="relative transition-all border-2 rounded-full"
                            :class="isSelected(user) ? 'border-primary' : 'border-transparent'"
                        >
                            <div class="border-2 border-transparent rounded-full">
                                <AuthUserAvatar
                                    :user="user"
                                    size="h-6 w-6"
                                    :clickable-avatar="false"
                                />
                            </div>
                            <transition
                                enter-active-class="duration-150 ease-linear "
                                enter-from-class="transform translate-y-[2px] opacity-0"
                                enter-to-class="transform translate-y-0 opacity-100"
                                leave-active-class="duration-150 ease-linear "
                                leave-from-class="transform translate-y-0 opacity-100"
                                leave-to-class="transform translate-y-[2px] opacity-0"
                            >
                                <div
                                    v-if="isSelected(user)"
                                    class="absolute right-0 bottom-0 transform translate-x-1/2 translate-y-[.10rem] h-4 w-4 rounded-full flex bg-primary items-center justify-center"
                                >
                                    <i class="fa-solid fa-sharp fa-check text-[.6rem] text-white"></i>
                                </div>
                            </transition>
                        </div>
                        <div>
                            <span class="block text-sm"> {{ user.name }} </span>
                            <span class="block -mt-1 text-xs font-light text-neutral-600 group-hover:text-primary">
                                {{ $t(`auth.user_role.${user.role}`) }}
                                <span v-if="user.isInactive"> ({{ $t('misc.inactive') }}) </span>
                            </span>
                        </div>
                        <div
                            v-if="isSelected(user)"
                            class="absolute top-0 bottom-0 right-0 flex items-center pr-3"
                        >
                            <a
                                href="#"
                                @click.prevent.stop="unselect(user)"
                            >
                                <i class="text-neutral-400 fa-regular fa-xmark hover:text-neutral-600"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </Submenu>
</template>
